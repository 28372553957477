import {
    FLASH_SALES_FAIL,
    FLASH_SALES_REQUEST,
    FLASH_SALES_SUCCESS
} from "../../../shared/constants";

const initialState = {
    loading: false,
    items: [],
    error: null 
};

const flashSalesReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FLASH_SALES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case FLASH_SALES_SUCCESS:
            return {
                ...state,
                loading: false,
                items: payload.products,
                error: null,
            };
        case FLASH_SALES_FAIL:
            return {
                ...state,
                loading: false,
                items: [],
                error: payload.error,
            };
        default:
            return state;
    }
};
export default flashSalesReducer;
