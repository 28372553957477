import {
  ORDER_REQUEST,
  ORDER_SUCCESS,
  ORDER_FAIL,
} from "../../../shared/constants";

const initialState = {
  loading: false,
  order: null,
  error: null,
};

const orderReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ORDER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        order: payload.order,
        error: null,
      };
    case ORDER_FAIL:
      return {
        ...state,
        loading: false,
        order: null,
        error: payload.error,
      };
    default:
      return state;
  }
};
export default orderReducer;
