import { combineReducers } from "redux";
import orders from "./ordersReducer";
import order from "./orderReducer";
import payment from "./paymentReducer";
import emailOrder from "./emailOrderReducer";
import payFacility from "./payFacilityReducer";

const rootOrdersReducer = combineReducers({
  orders,
  order,
  emailOrder,
  payFacility,
  payment
});
export default rootOrdersReducer;
