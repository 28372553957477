import {
  ABOUT_US_REQUEST,
  ABOUT_US_SUCCESS,
  ABOUT_US_FAIL,
} from "../../../shared/constants";

const initialState = {
  loading: false,
  about_us: {},
  error: null,
};

const aboutUsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case ABOUT_US_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ABOUT_US_SUCCESS:
      return {
        ...state,
        loading: false,
        about_us: payload.about_us,
        error: null,
      };
    case ABOUT_US_FAIL:
      return {
        ...state,
        loading: false,
        about_us: {},
        error: payload.error,
      };
    default:
      return state;
  }
};
export default aboutUsReducer;
