import {
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from "../../../shared/constants";

const initialState = {
  loading: false,
  message: null,
  statusCode: 200,
  error: null,
};

const registerReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REGISTER_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        loading: false,
        message: payload.message,
        statusCode: payload.statusCode,
        error: null,
      };
    case REGISTER_FAIL:
      return {
        ...state,
        loading: false,
        message: payload.message,
        statusCode: payload.statusCode,
        error: payload.errors,
      };
    default:
      return state;
  }
};
export default registerReducer;
