import {
  WILAYAS_REQUEST,
  WILAYAS_SUCCESS,
  WILAYAS_FAIL,
} from "../../../shared/constants";

const initialState = {
  loading: false,
  wilayas: [],
  error: null,
};

const wilayasReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case WILAYAS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case WILAYAS_SUCCESS:
      return {
        ...state,
        loading: false,
        wilayas: payload.wilayas,
        error: null,
      };
    case WILAYAS_FAIL:
      return {
        ...state,
        loading: false,
        wilayas: [],
        error: payload.error,
      };
    default:
      return state;
  }
};
export default wilayasReducer;
