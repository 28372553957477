import {
  GETCART_REQUEST,
  GETCART_SUCCESS,
  GETCART_FAIL,
} from "../../../shared/constants";
import { api } from "../../../shared/shared";

export const fetchGetCartBegin = () => ({
  type: GETCART_REQUEST,
});

export const fetchGetCartSuccess = (items) => ({
  type: GETCART_SUCCESS,
  payload: { items },
});

export const fetchGetCartFailure = (error) => ({
  type: GETCART_FAIL,
  payload: { error },
});

export function fetchGetCart() {
  return async (dispatch) => {
    dispatch(fetchGetCartBegin());
    api
      .get("/api/get-cart")
      .then((res) => {
        if (res.data.success) dispatch(fetchGetCartSuccess(res.data.data));
        else dispatch(fetchGetCartFailure(res.data.message));
      })
      .catch((error) => {
        dispatch(fetchGetCartFailure(error.message));
      });
  };
}
