import {
    SHOP_REQUEST,
    SHOP_SUCCESS,
    SHOP_FAIL,
} from '../../../shared/constants';

const initialState = {
    loading: false,
    items: [],
    options: {},
    error: null,
    links: {},
    meta: {}
};


const shopReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SHOP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case SHOP_SUCCESS:
            return {
                ...state,
                loading: false,
                items: payload.data.data,
                options: payload.data.options,
                links: payload.data.links,
                meta: payload.data.meta,
                error: null,
            };
        case SHOP_FAIL:
            return {
                ...state,
                loading: false,
                items: [],
                error: payload.error,
            };
        default:
            return state;
    }
};
export default shopReducer;