import {
  WISHLIST_FAIL,
  WISHLIST_REQUEST,
  WISHLIST_SUCCESS,
} from "../../../shared/constants";
import { api } from "../../../shared/shared";

const fetchWishlistBegin = () => ({
  type: WISHLIST_REQUEST,
});

const fetchWishlistSuccess = (products) => ({
  type: WISHLIST_SUCCESS,
  payload: { products },
});

const fetchWishlistFailure = (error) => ({
  type: WISHLIST_FAIL,
  payload: { error },
});

const fetchWishlistAction = () => (dispatch) => {
  dispatch(fetchWishlistBegin());
  api
    .get("api/wishlist")
    .then((res) => {
      if (res.data.success) {
        dispatch(fetchWishlistSuccess(res.data.data));
      } else {
        dispatch(fetchWishlistFailure(res.data.message));
      }
    })
    .catch((error) => {
      dispatch(fetchWishlistFailure(error.message));
    });
};
export default fetchWishlistAction;
