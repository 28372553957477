import {
  TERMS_AND_POLICIES_REQUEST,
  TERMS_AND_POLICIES_SUCCESS,
  TERMS_AND_POLICIES_FAIL,
} from "../../../shared/constants";

const initialState = {
  loading: false,
  terms_and_policies: {},
  error: null,
};

const termsAndPoliciesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TERMS_AND_POLICIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TERMS_AND_POLICIES_SUCCESS:
      return {
        ...state,
        loading: false,
        terms_and_policies: payload.terms_and_policies,
        error: null,
      };
    case TERMS_AND_POLICIES_FAIL:
      return {
        ...state,
        loading: false,
        terms_and_policies: {},
        error: payload.error,
      };
    default:
      return state;
  }
};
export default termsAndPoliciesReducer;
