import React from "react";
import ReactDOM from "react-dom";
import "./i18n";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Provider } from 'react-redux';
import { store } from "./redux/store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
