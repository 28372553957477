import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationAR from "./locales/ar.json";
import translationFR from "./locales/fr.json";
import translationEN from "./locales/en.json";

// the translations
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  ar: {
    translation: translationAR,
  },
};

i18n.use(detector).use(initReactI18next).init({
  resources,
  lng: 'fr',
  fallbackLng: "ar",
});

export default i18n;
