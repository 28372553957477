import {
  CHECKOUT_PRODUCT_REQUEST,
  CHECKOUT_PRODUCT_SUCCESS,
  CHECKOUT_PRODUCT_FAIL,
} from "../../shared/constants";

const initialState = {
  statusCode: 200,
  message: "",
  order: null,
  errors: null,
  loading: false,
};

const checkoutProductReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CHECKOUT_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
        message: null,
      };
    case CHECKOUT_PRODUCT_SUCCESS:
      return {
        ...state,
        message: payload.message,
        statusCode: payload.statusCode,
        order: payload.order,
        errors: null,
        loading: false,
      };
    case CHECKOUT_PRODUCT_FAIL:
      return {
        ...state,
        statusCode: payload.statusCode,
        message: payload.message,
        errors: payload.errors,
        loading: false,
      };
    default:
      return state;
  }
};
export default checkoutProductReducer;
