import {
    SLIDER_REQUEST,
    SLIDER_SUCCESS,
    SLIDER_FAIL, 
} from '../../shared/constants';

const initialState = {
    loading: false,
    sliders: [],
    error: null 
};


const sliderReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SLIDER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case SLIDER_SUCCESS:
            return {
                ...state,
                loading: false,
                sliders: payload.sliders,
                error: null,
            };
        case SLIDER_FAIL:
            return {
                ...state,
                loading: false,
                sliders: [],
                error: payload.error,
            };
        default:
            return state;
    }
};
export default sliderReducer;