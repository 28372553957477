import { combineReducers } from "redux";
import getCart from "./getCartReducer";
import addToCart from "./addToCartReducer";
import addToCartPreorder from "./addToCartPreorderReducer";
import removeItem from "./removeItemReducer";
import destroyCart from "./destroyCartReducers";
import orderNow from "./orderNowReducer";
import updateQuantityItemCart from "./updateQuantityItemCartReducer";
import applyCoupon from "./applyCouponReducer";
import removeCoupon from "./removeCouponReducer";

export default combineReducers({
  getCart,
  addToCart,
  updateQuantityItemCart,
  removeItem,
  destroyCart,
  addToCartPreorder,
  orderNow,
  applyCoupon,
  removeCoupon,
});
