import {
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAIL,
  USER_LOGOUT,
} from "../../../shared/constants";
import { api } from "../../../shared/shared";

const fetchUserBegin = () => ({
  type: USER_REQUEST,
});

const fetchUserSuccess = (user) => ({
  type: USER_SUCCESS,
  payload: { user },
});

const fetchUserFailure = (error) => ({
  type: USER_FAIL,
  payload: { error },
});

const fetchLogoutSuccess = () => ({
  type: USER_LOGOUT,
  payload: {},
});

export const fetchUserAction = () => (dispatch) => {
  dispatch(fetchUserBegin());
  api
    .get("api/user")
    .then((res) => {
      if (res.data.success) {
        dispatch(fetchUserSuccess(res.data.data));
      } else {
        dispatch(fetchUserFailure(res.data.message));
      }
    })
    .catch((error) => {
      dispatch(fetchUserFailure(error.response.data.message));
    });
};

export const fetchLogoutAction = () => (dispatch) => {
  api.get("api/logout").then((res) => {
    if (res.data.success) {
      dispatch(fetchLogoutSuccess(res.data.data));
      localStorage.setItem('isLogged', false);
    }
  });
};
